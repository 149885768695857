@import "view/styles/variables";

.loaderContainer,
.errorContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    background-color: $white;
    padding: 2em;
    border-radius: 3px;
  }
}

.doctorScreen {
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;

  .container {
    background-color: $white;
    border-radius: 3px;
    width: 100%;
    padding: 2em;
  }
}
