@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap);
.styles_spinner__3wee2{display:inline-block;position:relative;width:80px;height:80px}.styles_spinner__3wee2 div{animation:styles_spinner__3wee2 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;transform-origin:40px 40px}.styles_spinner__3wee2 div::after{content:" ";display:block;position:absolute;width:7px;height:7px;border-radius:50%;background:black;margin:-4px 0 0 -4px}.styles_spinner__3wee2 div:nth-child(1){animation-delay:-0.036s}.styles_spinner__3wee2 div:nth-child(1)::after{top:63px;left:63px}.styles_spinner__3wee2 div:nth-child(2){animation-delay:-0.072s}.styles_spinner__3wee2 div:nth-child(2)::after{top:68px;left:56px}.styles_spinner__3wee2 div:nth-child(3){animation-delay:-0.108s}.styles_spinner__3wee2 div:nth-child(3)::after{top:71px;left:48px}.styles_spinner__3wee2 div:nth-child(4){animation-delay:-0.144s}.styles_spinner__3wee2 div:nth-child(4)::after{top:72px;left:40px}.styles_spinner__3wee2 div:nth-child(5){animation-delay:-0.18s}.styles_spinner__3wee2 div:nth-child(5)::after{top:71px;left:32px}.styles_spinner__3wee2 div:nth-child(6){animation-delay:-0.216s}.styles_spinner__3wee2 div:nth-child(6)::after{top:68px;left:24px}.styles_spinner__3wee2 div:nth-child(7){animation-delay:-0.252s}.styles_spinner__3wee2 div:nth-child(7)::after{top:63px;left:17px}.styles_spinner__3wee2 div:nth-child(8){animation-delay:-0.288s}.styles_spinner__3wee2 div:nth-child(8)::after{top:56px;left:12px}@keyframes styles_spinner__3wee2{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}

.styles_pdf__2E_so{margin:0 auto;display:block}

.styles_patientStatus__xdKbl{margin-top:1em}.styles_patientStatus__xdKbl p{margin-bottom:0}.styles_patientStatus__xdKbl .styles_formStatus__3co-Y{display:inline-block;margin-top:0}.styles_patientStatus__xdKbl .styles_statusText__2tSWV{font-weight:600;margin-top:0.5em}@media (min-width: 37.5em){.styles_patientStatus__xdKbl .styles_statusText__2tSWV{display:inline-block;margin-top:0;margin-left:0.25em}}.styles_patientStatus__xdKbl .styles_statusText__2tSWV.styles_submitted__1Z96x{color:#25c525}.styles_patientStatus__xdKbl .styles_lineItem__5iQsn{font-size:.8rem}.styles_patientStatus__xdKbl .styles_lineItem__5iQsn .styles_value__IeI1E{font-weight:600}

.styles_patientStatus__3PXO_{margin-top:1em}.styles_patientStatus__3PXO_ p{margin-bottom:0}.styles_patientStatus__3PXO_ .styles_formStatus__2IVhY{display:inline-block;margin-top:0}.styles_patientStatus__3PXO_ .styles_statusText__1PESX{font-weight:600;margin-top:0.5em}@media (min-width: 37.5em){.styles_patientStatus__3PXO_ .styles_statusText__1PESX{display:inline-block;margin-top:0;margin-left:0.25em}}.styles_patientStatus__3PXO_ .styles_statusText__1PESX.styles_submitted__bkvuW{color:#25c525}.styles_patientStatus__3PXO_ .styles_lineItem__p3cU5{font-size:.8rem}.styles_patientStatus__3PXO_ .styles_lineItem__p3cU5 .styles_value__3TtzZ{font-weight:600}

.styles_doctorGreeting__2ByIK h1{margin-top:0;font-size:1.2rem}@media (min-width: 37.5em){.styles_doctorGreeting__2ByIK h1{font-size:2.2rem}}

.styles_loaderContainer__1nMIK,.styles_errorContainer__bUIl2{height:100vh;width:100%;display:flex;align-items:center;justify-content:center}.styles_loaderContainer__1nMIK p,.styles_errorContainer__bUIl2 p{background-color:#fff;padding:2em;border-radius:3px}.styles_doctorScreen__3qIM1{display:flex;margin-top:1em;margin-bottom:1em}.styles_doctorScreen__3qIM1 .styles_container__2Q5-b{background-color:#fff;border-radius:3px;width:100%;padding:2em}

.styles_form__2NDwI{display:block}.styles_form__2NDwI .styles_textField__3XCie{width:100%}.styles_form__2NDwI .styles_submitButton__298s3{margin-top:1em;display:block}.styles_form__2NDwI .styles_signaturePad__3Y5tF{margin-top:2em}.styles_form__2NDwI .styles_signaturePad__3Y5tF p{margin-bottom:0.5em}.styles_form__2NDwI .styles_signaturePad__3Y5tF .styles_sigCanvas__1h6h8{border:1px solid #b5b3ae;border-radius:4px;width:100%}.styles_form__2NDwI .styles_clearButton__jPzxB{display:block}.styles_form__2NDwI .styles_checkBox__3EQZd{margin-top:1em}input[type=date]:required:invalid::-webkit-datetime-edit{color:transparent}input[type=date]:focus::-webkit-datetime-edit{color:black !important}

.styles_loaderContainer__1eiVT,.styles_errorContainer__2WD2S{height:100vh;display:flex;align-items:center;justify-content:center}.styles_loaderContainer__1eiVT p,.styles_errorContainer__2WD2S p{background-color:#fff;padding:2em;border-radius:3px}.styles_container__2Yo2v{background-color:#fff;padding:2em;border-radius:3px;margin-top:1em;margin-bottom:1em}@media (min-width: 48em){.styles_container__2Yo2v{margin-top:5em;margin-bottom:5em}}.styles_container__2Yo2v h1{margin-top:0;margin-bottom:1em}.styles_container__2Yo2v .styles_error__28JVJ{color:#ff0a0a;font-weight:600}

.styles_notFound__1VN79{display:flex;align-items:center;justify-content:center;height:100vh}

body{background-color:#f5f5f5}h1,h2,h3,h4,h5,h6,a,p{font-family:"Raleway",sans-serif}a{text-decoration:none}.view_appContainer__2jCRI{max-width:48em;margin:0 auto}

