@import "view/styles/variables";

.doctorGreeting {
  h1 {
    margin-top: 0;
    font-size: $font-md;

    @media (min-width: $screen-sm) {
      font-size: $font-xl;
    }
  }
}
