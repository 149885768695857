@import "view/styles/variables";

.form {
  display: block;

  .textField {
    width: 100%;
  }

  .submitButton {
    margin-top: 1em;
    display: block;
  }

  .signaturePad {
    margin-top: 2em;

    p {
      margin-bottom: 0.5em;
    }

    .sigCanvas {
      border: 1px solid $grey;
      border-radius: 4px;
      width: 100%;
    }
  }

  .clearButton {
    display: block;
  }

  .checkBox {
    margin-top: 1em;
  }
}

input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
  color: black !important;
}