@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');
@import "./variables.scss";

body {
  background-color: $off-white;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p {
  font-family: $main-font;
}

a {
  text-decoration: none;
}
