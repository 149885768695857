@import "view/styles/variables";

.loaderContainer,
.errorContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    background-color: $white;
    padding: 2em;
    border-radius: 3px;
  }
}

.container {
  background-color: $white;
  padding: 2em;
  border-radius: 3px;
  margin-top: 1em;
  margin-bottom: 1em;

  @media (min-width: $screen-md) {
    margin-top: 5em;
    margin-bottom: 5em;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 1em;
  }

  .error {
    color: $red;
    font-weight: $font-xbold;
  }
}
