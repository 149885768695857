@import "view/styles/variables";

.patientStatus {
  margin-top: 1em;

  p {
    margin-bottom: 0;
  }

  .formStatus {
    display: inline-block;
    margin-top: 0;
  }

  .statusText {
    font-weight: $font-xbold;
    margin-top: 0.5em;

    @media(min-width: $screen-sm) {
      display: inline-block;
      margin-top: 0;
      margin-left: 0.25em;
    }

    &.submitted {
      color: $green;
    }
  }

  .lineItem {
    font-size: $font-xs;

    .value {
      font-weight: $font-xbold;
    }
  }
}
