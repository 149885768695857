$main-font: 'Raleway', sans-serif;

$font-xxxl: 3.2rem;
$font-xxl: 2.8rem;
$font-xl: 2.2rem;
$font-lg: 1.8rem;
$font-md: 1.2rem;
$font-sm: 1rem;
$font-xs: 0.8rem;

$font-xbold: 600;
$font-bold: 500;
$font-regular: 400;
$font-medium: 300;
$font-light: 200;

$off-white: #f5f5f5;
$white: #ffffff;
$orange: #dba227;
$grey: #b5b3ae;
$dark-blue: #3a4c69;
$ice-blue: #c7dcff;
$red: #ff0a0a;
$green: #25c525;

$screen-xs: 25em;
$screen-sm: 37.5em;
$screen-md: 48em;
$screen-lg: 60em;
$screen-xl: 80em;
$screen-xxl: 120em;
